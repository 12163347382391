import './Tabela.css';

const Tabela = () => {

    return (
        <div id='tabela'>
            <section className='tabela__sections--container'>
                <section className='firstTable'>
                    <h2 className='tabela__h2'>Ciclo 1,2 e 3</h2>
                    <table>
                        {/*Cabeçalho da tabela*/}
                        <thead>
                            <tr>
                                <th></th>
                                <th className='stretched'>Lanche coletivo</th>
                            </tr>
                        </thead>

                        {/*Corpo da tabela*/}
                        <tbody>
                            <tr>
                                <td>Avulso</td>
                                <td>10,00</td>
                            </tr>

                            <tr>
                                <td colSpan={2}>A partir de 15 dias, desconto de R$1,00 / dia</td>
                            </tr>
                        </tbody>
                    </table>
                </section>

                <section>
                    <h2 className='tabela__h2'>1º ao 5º ano</h2>
                    <table>
                        {/*Cabeçalho da tabela*/}
                        <thead>
                            <tr>
                                <th></th>
                                <th>Kit 01</th>
                                <th>Kit 02</th>
                            </tr>
                        </thead>

                        {/*Corpo da tabela*/}
                        <tbody>
                            <tr>
                                <td>Avulso</td>
                                <td>12,00</td>
                                <td>14,00</td>
                            </tr>

                            <tr>
                                <td colSpan={3} className=''>A partir de 15 dias, desconto de R$1,00 / dia</td>
                            </tr>
                        </tbody>
                    </table>
                </section>

                <section>
                    <table>
                        {/*Cabeçalho da tabela*/}
                        <thead>
                            <tr>
                                <th></th>
                                <th className='stretched'>Almoço</th>
                            </tr>
                        </thead>

                        {/*Corpo da tabela*/}
                        <tbody>
                            <tr>
                                <td>Avulso</td>
                                <td>18,00</td>
                            </tr>

                            <tr>
                                <td colSpan={2}>A partir de 15 dias, desconto de R$1,00 / dia</td>
                            </tr>
                        </tbody>
                    </table>
                </section>
            </section>


        </div >
    )
}

export default Tabela;