import './App.css';
import Cardapio from './Components/Cardapio';
import Contato from './Components/Contato';
import Passo from './Components/Passo';
import Intro from './Components/Intro';
import Menu from './Components/Menu';
import QuemSomos from './Components/QuemSomos';
import Servicos from './Components/Servicos';
import Valores from './Components/Valores';

function App() {
  return (
    <div className="App">
      <header>
        <Intro />
        <Menu />
      </header>

      <main>
        <QuemSomos />
        <Servicos />
        <Valores />
        <Cardapio />
        <Passo />
        <Contato />
      </main>
    </div>
  );
}

export default App;
