import './Contato.css';

const Contato = () => {
    return (
        <section id='contato' className='container__all'>
            <h2 className='h2__container contato__h2--color'>Contato</h2>
            <div className='contato__container secondContainer__all'>
                <div className='p__opacity contato__opacity'></div>
                <div className='contato__text--container'>
                    <p>Em caso de dúvidas ou sugestões, entre em contato conosco através do email:</p>
                    <a href='mailto:merakicomidasaudavel@gmail.com' className='contato__a' target='_blank'>merakicomidasaudavel@gmail.com</a>
                </div>

            </div>
        </section>
    )
}

export default Contato;