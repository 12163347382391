import './QuemSomos.css';

const QuemSomos = () => {
    return (
        <section id='quemSomos' className='container__all'>
            <h2 className='h2__container h2__color'>Quem somos?</h2>

            <div className='quemSomos__container secondContainer__all'>
                <div className='p__opacity quemSomos__opacity'></div>
                <p className='quemSomos__p'>
                    Somos uma empresa que preza pela alimentação infantil saudável e de qualidade.
                    Meraki significa “fazer com a alma, criatividade ou amor” e é essa mensagem que
                    queremos passar através dos nossos serviços: que seus filhos desfrutem de refeições
                    saborosas e adequadas para sua saúde, feitas pelas mãos da nossa equipe.
                    Esse time é composto por profissionais do ramo alimentício, liderados pela
                    gastrônoma Lídia Mélo, que conta com mais de 10 anos de carreira.
                </p>
            </div>
        </section>
    )
}

export default QuemSomos;