import { Link } from 'react-scroll';
import './Menu.css';

const Menu = () => {
    const menuItens = [
        {
            id: 1,
            desc: 'Quem somos?',
            href: 'quemSomos'
        },

        {
            id: 2,
            desc: 'Nossos serviços',
            href: 'servicos'
        },

        {
            id: 3,
            desc: 'Valores',
            href: 'valores'
        },

        {
            id: 4,
            desc: 'Cardápios',
            href: 'cardapio'
        },

        {
            id: 5,
            desc: 'Passo a Passo',
            href: 'passo'
        },

        {
            id: 6,
            desc: 'Contato',
            href: 'contato'
        }
    ];

    return (
        <nav id='nav'>
            <ul>
                {menuItens.map((item) => (
                    <li key={item.id}>
                        <Link className='link'
                            to={item.href}
                            smooth={true}
                            duration={1000}
                        >
                            {item.desc}
                        </Link>
                    </li>
                ))}
            </ul>
        </nav>
    );
};

export default Menu;
